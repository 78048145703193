<template>
  <div class="tabs" :class="{ bottom, fluid, progress, scrollable }" ref="container">
    <template v-for="(tab, i) in tabs" :key="tab.name">
      <component :is="tab.to ? NuxtLink : 'button'" :to="tab.to" class="tab" ref="items" :replace="replace" type="button"
                 @click="(tab.onClick || progress) ? tab.onClick?.(tab, i) : selected = i" :class="{'current': selected === i || tab.current || (progress && i <= selected)}">
        <i v-if="tab.icon" :class="`icon nicon-${tab.icon}`"></i>
        <div v-if="tab.isNew" class="new-dot small" />
        {{ progress ? `${i + 1}. ` : '' }}{{ tab.name }}{{ (progress && tab.required) ? '*' : '' }}
        <span v-if="tab.note !== undefined" class="note">{{ tab.note }}</span>
      </component>
    </template>
  </div>
</template>

<script setup>
import NuxtLink from '#app/components/nuxt-link'

const props = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  modelValue: Number,
  bottom: Boolean,
  fluid: Boolean,
  progress: Boolean,
  scrollable: Boolean,
  replace: Boolean
})
const emit = defineEmits(['update:modelValue'])

const container = ref()
const items = ref([])

const selected = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

watch([selected, items], ([i]) => {
  if (props.scrollable) {
    const offset = items.value[i]?.offsetLeft
    if (typeof offset === 'number') container.value.scrollTo({ left: offset, behavior: 'smooth' })
  }
}, { immediate: true, deep: true })
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid var(--color-separator);
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  & .tab {
    background: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 18px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-secondary);
    border: none;
    border-top: 1px solid transparent;
    transition: .1s border-color;
    @media (max-width: 920px) {
      padding: 10px 11px;
      font-size: 11px;
      line-height: 15px;
    }
    &.current, &.router-link-exact-active {
      cursor: default;
      pointer-events: none;
      border-top: 4px solid var(--color-primary);
      color: var(--color-elements-primary);
    }
    & .icon {
      margin-right: 10px;
    }
    & .note {
      margin-left: 5px;
      color: var(--color-elements-tertiary);
    }
  }

  &.bottom {
    border-top: none;
    border-bottom: 1px solid var(--color-separator);
    .tab {
      border-top: none;
      border-bottom: 1px solid transparent;
      &.current, &.router-link-exact-active {
        border-top: none;
        border-bottom: 4px solid var(--color-primary);
      }
    }
  }
  &.fluid {
    flex-wrap: nowrap;
    .tab {
      flex: 1;
    }
  }
  &.progress {
    .tab {
      cursor: default;
    }
  }
  &.scrollable {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    .tab {
      padding: 17px 12px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:not(.progress) .tab:hover {
    border-color: var(--color-primary);
    opacity: 1;
  }
}
</style>
